import { Route, Routes } from 'react-router-dom';

import { Calculator } from './screens/home';

function App() {
	return (
		<Calculator />
	);
}

export default App;
