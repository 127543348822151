import { useState,useEffect } from 'react';

export function Calculator() {
  	const getMonthDifference = (startDate: any, endDate: any): number => {
	  return (
	    endDate.getMonth() -
	    startDate.getMonth() +
	    12 * (endDate.getFullYear() - startDate.getFullYear())
	  );
	}

	const padZero = (n: number): string => {
	    return (n < 10) ? ("0" + n) : String(n);
	}

	const [monthOfManu, setMonthOfManu] = useState(1);
  	const [yearOfManu, setYearOfManu] = useState(2019);
  	const vehicleage = getMonthDifference(new Date('2019-01-01'), new Date());



	const [E19, setE19] = useState(25000);
	const [E20, setE20] = useState("A");
	const [E22, setE22] = useState(vehicleage/12);
	const [G29, setG29] = useState(4401383);
	const [E30, setE30] = useState(1.751);
	const [H30, setH30] = useState(0);
	const [H31, setH31] = useState(0);
	const [E32, setE32] = useState("yes");
	const [H32, setH32] = useState(0);
	const [H33, setH33] = useState(0);
	const [E34, setE34] = useState(85);
	const [H34, setH34] = useState(0);
	const [E35, setE35] = useState(20);
	const [H35, setH35] = useState(0);
	const [H36, setH36] = useState(0);
	const [E37, setE37] = useState(0);
	const [F37, setF37] = useState(0);
	const [H37, setH37] = useState(0);
	const [E38, setE38] = useState(18);
	const [H38, setH38] = useState(0);
	const [H39, setH39] = useState(0);
	const [H41, setH41] = useState(0);
	const [H42, setH42] = useState(0);
	const [F43, setF43] = useState("no");
	const [G43, setG43] = useState(1);
	const [H43, setH43] = useState(0);
	const [F44, setF44] = useState("yes");
	const [G44, setG44] = useState(1);
	const [H44, setH44] = useState(0);
	const [F45, setF45] = useState("yes");
	const [G45, setG45] = useState(2);
	const [H45, setH45] = useState(0);
	const [F46, setF46] = useState("no");
	const [G46, setG46] = useState(0);
	const [H46, setH46] = useState(0);
	const [H47, setH47] = useState(0);
	const [H48, setH48] = useState(0);
	const [E49, setE49] = useState(12);
	const [H49, setH49] = useState(0);
	const [H50, setH50] = useState(0);
	const [H51, setH51] = useState(0);

  	
  	const calcGVC = () => {
  		const vehicleage = getMonthDifference(new Date(yearOfManu+'-'+padZero(monthOfManu)+'-01'), new Date());
  		setE22(vehicleage/12);
  		// Zone	0-5			6-7		7-10
		// A		1.751%	1.795%	1.839%
		// B		1.743%	1.787%	1.830%
		// C		1.726%	1.770%	1.812%

  		if(E22 < 6 && E20 == "A"){
  			setE30(1.751);
  		}
  		if(E22 < 6 && E20 == "B"){
  			setE30(1.743);
  		}
  		if(E22 < 6 && E20 == "C"){
  			setE30(1.726);
  		}

  		if(E22 >= 6 && E22 < 7 && E20 == "A"){
  			setE30(1.795);
  		}
  		if(E22 >= 6 && E22 < 7 && E20 == "B"){
  			setE30(1.787);
  		}
  		if(E22 >= 6 && E22 < 7 && E20 == "C"){
  			setE30(1.770);
  		}

  		if(E22 >= 7 && E20 == "A"){
  			setE30(1.839);
  		}
  		if(E22 >= 7 && E20 == "B"){
  			setE30(1.830);
  		}
  		if(E22 >= 7 && E20 == "C"){
  			setE30(1.812);
  		}

  		setH30(Math.round(G29*E30/100));

  		if(G29 <= 0){
  			setH31(0);
  		}else{
  			if(E19 > 12000){
  				setH31((Math.round(E19-12000)*27/100));
			}else{
				setH31(0);
			}
		}
		setH34(Math.round((H30+H31)*E34/100));

		if(E32 == "yes"){
			setH32(Math.round((H30+H31-H34)*15/100));
		}else{
			setH32(0);
		}
		if(E35 == 0){
			setF37(E37);
		}else{
			setF37(0);
		}
		setH37(Math.round((H30+H31-H34)*F37/100));

		setH33(Math.round(H30+H31-H34+H32+H37-H35));
		setH35(Math.round((H30+H31-H34+H32)*E35/100));
		setH36(Math.round(H30+H31-H34-H35+H32+H37));

		setH42(Math.round(375));
		setH43(Math.round(0));
		setH44(Math.round(0));
		if(F45 == "yes"){
			setH45(Math.round(G45*50));
		}else{
			setH45(Math.round(0));
		}

		setH38(Math.round((H36+H42+H43+H44+H45)*E38/100));
		setH39(Math.round(H36+H38));
		
		if(E19 >= 0 && E19 <= 7500){
			setH41(15746);
		}
		if(E19 >= 7501 && E19 <= 12000){
			setH41(26935);
		}
		if(E19 >= 12001 && E19 <= 20000){
			setH41(33418);
		}
		if(E19 >= 20001 && E19 <= 40000){
			setH41(43037);
		}
		if(E19 >= 40001){
			setH41(41561);
		}

		if( F46 == "yes"){
			setH45(Math.round(75*G46));
		}else{
			setH46(Math.round(0));
		}

		setH48(Math.round(H41+H42+H43+H44+H45+H46+H47));
		setH49(Math.round(H41*E49/100));
		setH50(Math.round(H48+H49));
		setH51(Math.round(H39+H50));
  	}

  	useEffect(()=>{
  		calcGVC();
	}, [monthOfManu,yearOfManu,E22,E30,E19,E20,G29,E30,H30,E32,E34,E35,E37,E38,F43,G43,F44,G44,F45,G45,F46,G46]);
	// useEffect(()=>{
  	// 	calcGVC();
	// }, [monthOfManu,yearOfManu,E22,E30,E19,E20,G29,E30,H30,E32,E34,E35,E37,E38,F43,G43,F44,G44,F45,G45,F46,G46]);

  	// const [grossVehicleWeight, setGrossVehicleWeight] = useState(25000)
  	// const [zone, setZone] = useState('C')
  	// const [insuredDeclaredValue, setInsuredDeclaredValue] = useState(4401383)
  	// const [rateOfPremium, setRateOfPremium] = useState(1.751)
  	// const [addExtra15IMTNo, setAddExtra15IMTNo] = useState('yes')
  	// const [lessDiscountNewTariff, setLessDiscountNewTariff] = useState(85)
  	// const [lessNoClaimBonus,setLessNoClaimBonus] = useState(20)
  	// const [addLoading,setAddLoading] = useState(0)
  	// const [legalLiabilityPaidDriver, setLegalLiabilityPaidDriver] = useState('yes')
  	// const [legalLiabilityPaidDriverRate, setLegalLiabilityPaidDriverRate] = useState(1)

  	// const [paToPaidDriverCleaner, setPaToPaidDriverCleaner] = useState('yes')
  	// const [paToPaidDriverCleanerRate, setPaToPaidDriverCleanerRate] = useState(1)
	
	// const [llToCleanerCoolies, setLlToCleanerCoolies] = useState('yes')
  	// const [llToCleanerCooliesRate, setLlToCleanerCooliesRate] = useState(1)
  	// const [llToNonFarePayingPassengers, setLlToNonFarePayingPassengers] = useState('yes')
  	// const [llToNonFarePayingPassengersRate, setLlToNonFarePayingPassengersRate] = useState(1)

  	// const [odGST, setOdGST] = useState(18)
  	// const [lpGST, setlpGST] = useState(12)

  	// const [labelRateOfPremimumPercent, setLabelRateOfPremimumPercent] = useState(1.751)
  	// const [labelRateOfPremimum, setLabelRateOfPremimum] = useState()
  	// const [labelAdditionalPremiumabove12000Kg, setLabelAdditionalPremiumabove12000Kg] = useState()
  	// const [labelAddExtra15IMTNo, setLabelAddExtra15IMTNo] = useState()
  	// const [labelBasicOwnDamagePremium, setLabelBasicOwnDamagePremium] = useState()
  	// const [labelLessDiscountNewTariff, setLabelLessDiscountNewTariff] = useState()
  	// const [labelLessNoClaimBonus, setLabelLessNoClaimBonus] = useState()
  	// const [labelTotalA, setLabelTotalA] = useState()
  	// const [labeladdLoading, setLabeladdLoading] = useState()
  	// const [labelOdGST, setLabelOdGST] = useState()
  	// const [labelOdPremiumWithGST, setLabelOdPremiumWithGST] = useState()

  	// const [labelBasicActPremium, setLabelBasicActPremium] = useState()
  	// const [labelPAforOwnerDriver, setLabelPAforOwnerDriver] = useState()
  	// const [labelLegalLiabilityPaidDriver, setLabelLegalLiabilityPaidDriver] = useState()
  	// const [labelPaToPaidDriverCleaner, setLabelPaToPaidDriverCleaner] = useState()
  	// const [labelLlToCleanerCoolies, setLabelLlToCleanerCoolies] = useState()
  	// const [labelLlToNonFarePayingPassengers, setLabelLlToNonFarePayingPassengers] = useState()
  	// const [labelAdditionalTowing, setLabelAdditionalTowing] = useState()
  	// const [labelTotalB, setLabelTotalB] = useState()
  	// const [labelLpGST, setLabelLpGST] = useState()
  	// const [labelTPPremiumWithGST, setLabelTPPremiumWithGST] = useState()
  	// const [labelTotalPremium, setLabelTotalPremium] = useState()



  	return (
    <>

      <div className='md:max-w-2xl lg:max-w-6xl xl:max-w-7xl mx-auto p-6 text-sm'>
        <div className="my-8">
          <p className="text-base font-medium text-indigo-500">Goods Carrying Commercial Vehicle - Public Carrier  (Class A1) Premium</p>
          <p className='mt-2 mb-1 text-2xl font-semibold text-gray-100'>Quotation</p>
          <p className="mb-6 text-gray-300">
            Your Total Premium is {H51}.
          </p>
        </div>
        <div className='grid lg:grid-cols-2 gap-4'>
          <div className='lg:order-1'>

            <div className="text-gray-300 mb-10">
              <div className="flex justify-between py-3 border-b border-gray-500">
                <span className="text-gray-400">Year of Manufacture {E22}</span>
                <span><input className="appearance-none bg-transparent border-none text-right w-full text-yellow-300 mr-3 py-1 leading-tight focus:outline-none" type="text" placeholder="Month" aria-label="Month" value={monthOfManu} onChange={(e) => {
                	if(e.target.value){
                		setMonthOfManu(parseInt(e.target.value));
                	}

                }} /></span>
                <span><input className="appearance-none bg-transparent border-none text-right w-14 text-yellow-300 py-1 leading-tight focus:outline-none" type="text" placeholder="Year" aria-label="Year" value={yearOfManu} onChange={(e) => {
                	if(e.target.value){
                		setYearOfManu(parseInt(e.target.value));
                	}
                	
                }} /></span>
              </div>

              <div className="flex justify-between py-3 border-b border-gray-500">
                <span className="text-gray-400">Gross Vehicle Weight (in Kgs)</span>
                <span><input className="appearance-none bg-transparent border-none text-right w-full text-yellow-300 py-1 leading-tight focus:outline-none" type="text" placeholder="Enter Number" aria-label="Enter Number" value={E19} onChange={(e) => {
                	if(e.target.value){
                		setE19(parseInt(e.target.value));
                	}
                	
                }} /></span>
              </div>

              <div className="flex justify-between py-3 border-b border-gray-500">
                <span className="text-gray-400">Zone</span>
                <span>
					<select className="appearance-none bg-transparent border-none text-right w-full text-yellow-300 mr-3 py-1 leading-tight focus:outline-none" value={E20} onChange={(e) => {
						if(e.target.value){
	                		setE20(e.target.value);
	                	}
	                	
					}}>
						<option value="A" key="A">Metro</option>
						<option value="B" key="B">State Capital</option>
						<option value="C" key="C">Rest of India</option>
					</select>
                </span>
              </div>
            </div>
            <div className="text-gray-300">
              <div className="font-bold text-lg pb-3">Premium Details</div>
              <div className="font-semibold text-md">OWN DAMAGE</div>

              <div className="flex justify-between py-3 border-b border-gray-500">
                <span className="text-gray-400">Insured Declared Value</span>
                <span></span>
                <span></span>
                <span><input className="appearance-none bg-transparent border-none text-right w-full text-yellow-300 py-1 leading-tight focus:outline-none" type="text" placeholder="Year" aria-label="Year"  value={G29} onChange={(e) => {
                	if(e.target.value){
                		setG29(parseInt(e.target.value));
                	}
                	
                }} /></span>
              </div>

              <div className="flex justify-between py-3 border-b border-gray-500">
                <span className="text-gray-400">Rate of Premium</span>
                <span className="text-gray-400">{E30}%</span>
                <span></span>
                <span>{H30}</span>
              </div>
              <div className="flex justify-between py-3 border-b border-gray-500">
                <span className="text-gray-400">Additional Premium above 12000 Kg</span>
                <span></span>
                <span></span>
                <span>{H31}</span>
              </div>
              <div className="flex justify-between py-3 border-b border-gray-500">
                <span className="text-gray-400">Add Extra @15%- IMT No. 23</span>
                <span>
                	<select className="appearance-none bg-transparent border-none text-right w-full text-yellow-300 mr-3 py-1 px-2 leading-tight focus:outline-none" value={E32} onChange={(e) => {
                		if(e.target.value){
	                		setE32(e.target.value);
	                	}
	                	
                	}}>
						<option value="yes" key="yes">Yes</option>
						<option value="no" key="no">No</option>
					</select>
                </span>
                <span></span>
                <span>{H32}</span>
              </div>
              <div className="flex justify-between py-3 border-b border-gray-500">
                <span className="text-gray-400">Basic Own Damage Premium</span>
                <span></span>
                <span></span>
                <span>{H33}</span>
              </div>
              <div className="flex justify-between py-3 border-b border-gray-500">
                <span className="text-gray-400">Less Discount as per New Tariff</span>
                <span><input className="appearance-none bg-transparent border-none text-right w-14 text-yellow-300 mr-3 py-1 leading-tight focus:outline-none" type="text" placeholder="Year" aria-label="Year"  value={E34} onChange={(e) => {
                	if(e.target.value){
                		setE34(parseInt(e.target.value));
                	}
                	
                }} />%</span>
                <span></span>
                <span>{H34}</span>
              </div>
              <div className="flex justify-between py-3 border-b border-gray-500">
                <span className="text-gray-400">Less: No Claim Bonus:(%)</span>
                <span>
                	<select className="appearance-none bg-transparent border-none text-right w-full text-yellow-300 mr-3 py-1 px-2 leading-tight focus:outline-none" value={E35} onChange={(e) => {
                		if(e.target.value){
	                		setE35(parseInt(e.target.value));
	                	}
	                	
                	}}>
						<option value="0" key="0">0</option>
						<option value="20" key="20">20</option>
						<option value="25" key="25">25</option>
						<option value="35" key="35">35</option>
						<option value="45" key="45">45</option>
						<option value="50" key="50">50</option>
						<option value="65" key="65">65</option>
					</select>
                </span>
                <span></span>
                <span>{H35}</span>
              </div>
              <div className="flex justify-between py-3 border-b border-gray-500">
                <span className="text-gray-400">Total - A</span>
                <span></span>
                <span></span>
                <span>{H36}</span>
              </div>
              <div className="flex justify-between py-3 border-b border-gray-500">
                <span className="text-gray-400">Add: Loading: (%)</span>
                <span><input className="appearance-none bg-transparent border-none text-right w-14 text-yellow-300 mr-3 py-1 leading-tight focus:outline-none" type="text" placeholder="Year" aria-label="Year" value={E37} onChange={(e) => {
                	if(e.target.value){
                		setE37(parseInt(e.target.value));
                	}
                	
                }} /></span>
                <span>{F37}</span>
                <span>{H37}</span>
              </div>
              <div className="flex justify-between py-3 border-b border-gray-200">
                <span className="text-gray-400">GST(%)</span>
                <span>{E38}%</span>
                <span></span>
                <span>{H38}</span>
              </div>
              <div className="flex justify-between py-3 border-b border-gray-200">
                <span className="text-gray-300 font-bold">OD Premium With GST</span>
                <span></span>
                <span></span>
                <span className="text-gray-200 font-bold">{H39}</span>
              </div>

              <div className="font-semibold text-md mt-10">LIABILITY PREMIUM</div>
				<div className="flex justify-between py-3 border-b border-gray-500">
					<span className="text-gray-400">Basic Act.Premium</span>
					<span></span>
					<span></span>
					<span>{H41}</span>
				</div>
				<div className="flex justify-between py-3 border-b border-gray-500">
					<span className="text-gray-400">P. A. for Owner Driver</span>
					<span></span>
					<span></span>
					<span> {H42} </span>
				</div>
				<div className="flex justify-between py-3 border-b border-gray-500">
					<span className="text-gray-400">Legal Liability to Paid Driver(Yes/No)</span>
					<span>
						<select className="appearance-none bg-transparent border-none text-right w-full text-yellow-300 mr-3 py-1 px-2 leading-tight focus:outline-none" value={F43} onChange={(e) => {
							if(e.target.value){
		                		setF43(e.target.value);
		                	}
		                	
						}}>
							<option value="yes" key="yes">Yes</option>
							<option value="no" key="no">No</option>
						</select>
					</span>
					<span><input className="appearance-none bg-transparent border-none text-right w-14 text-yellow-300 mr-3 py-1 leading-tight focus:outline-none" type="text" placeholder="Year" aria-label="Year" value={G43} onChange={(e) => {
						if(e.target.value){
	                		setG43(parseInt(e.target.value));
	                	}
	                	
					}} /></span>
					<span>{H43}</span>
				</div>
				<div className="flex justify-between py-3 border-b border-gray-500">
					<span className="text-gray-400">P.A. To Paid Driver, Cleaner</span>
					<span>
						<select className="appearance-none bg-transparent border-none text-right w-full text-yellow-300 mr-3 py-1 px-2 leading-tight focus:outline-none" value={F44} onChange={(e) => {
							if(e.target.value){
		                		setF44(e.target.value);
		                	}
		                	
						}}>
							<option value="yes" key="yes">Yes</option>
							<option value="no" key="no">No</option>
						</select>
					</span>
					<span><input className="appearance-none bg-transparent border-none text-right w-14 text-yellow-300 mr-3 py-1 leading-tight focus:outline-none" type="text" placeholder="Year" aria-label="Year" value={G44} onChange={(e) => {
						if(e.target.value){
	                		setG44(parseInt(e.target.value));
	                	}
	                	
					}} /></span>
					<span>{H44}</span>
				</div>
				<div className="flex justify-between py-3 border-b border-gray-500">
					<span className="text-gray-400">L.L. To Cleaner & Coolies</span>
					<span>
						<select className="appearance-none bg-transparent border-none text-right w-full text-yellow-300 mr-3 py-1 px-2 leading-tight focus:outline-none" value={F45} onChange={(e) => {
							if(e.target.value){
		                		setF45(e.target.value);
		                	}
		                	
		                }}>
							<option value="yes" key="yes">Yes</option>
							<option value="no" key="no">No</option>
						</select>
					</span>
					<span><input className="appearance-none bg-transparent border-none text-right w-14 text-yellow-300 mr-3 py-1 leading-tight focus:outline-none" type="text" placeholder="Year" aria-label="Year" value={G45} onChange={(e) => {
						if(e.target.value){
	                		setG45(parseInt(e.target.value));
	                	}
	                	
	                }} /></span>
					<span>{H45}</span>
				</div>
				<div className="flex justify-between py-3 border-b border-gray-500">
					<span className="text-gray-400">L.L. To Non Fare Paying Passengers</span>
					<span>
						<select className="appearance-none bg-transparent border-none text-right w-full text-yellow-300 mr-3 py-1 px-2 leading-tight focus:outline-none" value={F46} onChange={(e) => {
							if(e.target.value){
		                		setF46(e.target.value);
		                	}
		                	
		                }}>
							<option value="yes" key="yes">Yes</option>
							<option value="no" key="no">No</option>
						</select>
					</span>
					<span><input className="appearance-none bg-transparent border-none text-right w-14 text-yellow-300 mr-3 py-1 leading-tight focus:outline-none" type="text" placeholder="Year" aria-label="Year" value={G46} onChange={(e) => {
							if(e.target.value){
		                		setG46(parseInt(e.target.value));
		                	}
		                	
		                }} /></span>
					<span>{H46}</span>
				</div>
				<div className="flex justify-between py-3 border-b border-gray-500">
					<span className="text-gray-400">Additional Towing</span>
					<span>0</span>
					<span></span>
					<span>{H47}</span>
				</div>
				<div className="flex justify-between py-3 border-b border-gray-500">
					<span className="text-gray-400">Total - B</span>
					<span></span>
					<span></span>
					<span>{H48} </span>
				</div>
				<div className="flex justify-between py-3 border-b border-gray-200">
					<span className="text-gray-400">GST(%)</span>
					<span>{E49}%</span>
					<span></span>
					<span>{H49}</span>
				</div>

              	<div className="flex justify-between py-3 border-b border-gray-200">
                	<span className="text-gray-200 font-bold">TP Premium With GST</span>
                	<span></span>
                	<span></span>
                	<span className="text-gray-200 font-bold">{H50} </span>
              	</div>
              	<div className="flex justify-between py-3 border-b border-gray-200">
                	<span className="text-gray-200 font-bold">TOTAL PREMIUM</span>
                	<span></span>
                	<span></span>
                	<span className="text-gray-200 font-bold">{H51}</span>
              	</div>

              	<div className="hidden flex justify-between py-3 border-b border-gray-500">
                	<span className="text-gray-400">Subtotal</span>
                	<span></span>
                	<span></span>
                	<span></span>
              	</div>
            </div>
          </div>

          
        </div>
      </div>
    </>
  )
}
